<template>
  <div class="sources">
    <ul>
      <li>
        <a target="_blank" href="https://quatrochan.github.io/Equal/">Equal UI</a>
      </li>
      <li>
        <a target="_blank" href="https://unsplash.com/@johnnydollar/collections">Unsplash</a>
      </li>
      <li>
        <a target="_blank" href="https://v3.vuejs.org/guide/typescript-support.html#development-tooling">Setup</a>
      </li>
      <li>
        <div>Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  </div>
</template>
